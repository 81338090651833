<template>
	<div id="app" :class="[page, classes]">
		<component :is="layout">
			<router-view :key="$route.fullPath" />
		</component>
	</div>
</template>

<script>
import { EventBus } from '@/event-bus';
const default_layout = 'Blank';

export default {
	name: 'RemovifySalesToolApp',
	computed: {
		layout() {
			return this.$route.meta.layout || default_layout;
		},
		page() {
			return this.$route.name || '';
		},
		isIdle() {
			return this.$store.state.idleVue.isIdle;
		},
	},
	data() {
		return {
			classes: '',
		};
	},
	mounted() {
		EventBus.$on('APP_CLASS_UPDATE', (payload) => {
			this.updateClasses(payload);
		});
	},
	methods: {
		updateClasses(className) {
			this.classes = className;
		},
	},
};
</script>

<style lang="scss">
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	background-color: #f1f2f5;

	&.page-not-found {
		background: none;
	}

	&.Success {
		background: linear-gradient(
			90deg,
			#ffffff 0%,
			#ffffff 50%,
			#f1f2f5 50%
		);

		@media screen and (max-width: 991px) {
			background: none;
		}
	}
}
</style>
