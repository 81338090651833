<template>
	<SlideYUpTransition :duration="animationDuration">
		<div
			class="modal fade"
			:class="[
				{ 'show d-block': show },
				{ 'd-none': !show },
				{ 'modal-mini': type === 'mini' },
				{ 'modal-headless': headless },
			]"
			v-show="show"
			tabindex="-1"
			role="dialog"
			:aria-hidden="!show"
			@mousedown.self="closeModalOutside"
		>
			<div
				class="modal-dialog modal-dialog-centered"
				:class="[
					{
						'modal-notice': type === 'notice',
						[`modal-${size}`]: size,
					},
					modalClasses,
				]"
			>
				<div
					class="modal-content"
					:class="[
						gradient ? `bg-gradient-${gradient}` : '',
						modalContentClasses,
					]"
				>
					<div
						class="modal-header"
						:class="[headerClasses]"
						v-if="$slots.header"
					>
						<slot name="header"></slot>
						<slot name="close-button">
							<button
								type="button"
								class="close"
								v-if="showClose"
								@click="closeModal"
								data-dismiss="modal"
								aria-label="Close"
							>
								<span :aria-hidden="!show">
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12.5274 9.99992L16.318 6.20939C16.7831 5.74424 16.7831 4.99007 16.318 4.52454L15.4755 3.68212C15.0104 3.21696 14.2562 3.21696 13.7907 3.68212L10.0002 7.47265L6.20963 3.68212C5.74448 3.21696 4.99031 3.21696 4.52478 3.68212L3.68236 4.52454C3.21721 4.98969 3.21721 5.74386 3.68236 6.20939L7.47289 9.99992L3.68236 13.7904C3.21721 14.2556 3.21721 15.0098 3.68236 15.4753L4.52478 16.3177C4.98994 16.7829 5.74448 16.7829 6.20963 16.3177L10.0002 12.5272L13.7907 16.3177C14.2558 16.7829 15.0104 16.7829 15.4755 16.3177L16.318 15.4753C16.7831 15.0101 16.7831 14.256 16.318 13.7904L12.5274 9.99992Z" fill="white"/>
									</svg>
								</span>
							</button>
						</slot>
					</div>

					<div class="modal-body" :class="bodyClasses">
						<slot></slot>
					</div>

					<div
						class="modal-footer"
						:class="footerClasses"
						v-if="$slots.footer"
					>
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';
import swal from 'sweetalert2';

export default {
	name: 'modal',
	components: {
		SlideYUpTransition,
	},
	props: {
		show: Boolean,
		showClose: {
			type: Boolean,
			default: true,
		},
		clickOutside: {
			type: Boolean,
			default: true,
		},
		outsideCloseMessage: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
			validator(value) {
				let acceptedValues = ['', 'notice', 'mini'];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Modal type (notice|mini|"") ',
		},
		modalClasses: {
			type: [Object, String],
			description: 'Modal dialog css classes',
		},
		size: {
			type: String,
			description: 'Modal size',
			validator(value) {
				let acceptedValues = ['', 'sm', 'lg', 'xl'];
				return acceptedValues.indexOf(value) !== -1;
			},
		},
		modalContentClasses: {
			type: [Object, String],
			description: 'Modal dialog content css classes',
		},
		gradient: {
			type: String,
			description: 'Modal gradient type (danger, primary etc)',
		},
		headless: {
			type: Boolean,
			description: 'Removes modal header',
		},
		headerClasses: {
			type: [Object, String],
			description: 'Modal Header css classes',
		},
		bodyClasses: {
			type: [Object, String],
			description: 'Modal Body css classes',
		},
		footerClasses: {
			type: [Object, String],
			description: 'Modal Footer css classes',
		},
		animationDuration: {
			type: Number,
			default: 200,
			description: 'Modal transition duration',
		},
	},
	methods: {
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		closeModalOutside() {
			if (this.clickOutside) this.closeModal();
			else if (this.outsideCloseMessage !== '') {
				swal.fire({
					title: 'Warning',
					text: this.outsideCloseMessage,
					showCancelButton: true,
					confirmButtonText: 'Discard & Exit',
					cancelButtonText: 'Cancel',
				}).then((result) => {
					if (result.value) {
						this.closeModal();
					}
				});
			}
		},
	},
	watch: {
		show(val) {
			let documentClasses = document.body.classList;
			if (val) {
				documentClasses.add('modal-open');
			} else {
				documentClasses.remove('modal-open');
			}
		},
	},
};
</script>
<style>
.modal.show {
	background-color: rgba(0, 0, 0, 0.7);
}
</style>
