import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store =  new Vuex.Store({
	state: {
		progress: 0,
		isLoading: false,
		isLoggedIn: false,
		user: null,
		reCaptchaSiteKey: '6LetzNsZAAAAAIUA3YWCMqHKAlvZzXj6Vxy4s71s',
		apiEndpoint: import.meta.env.VITE_APP_API_BASE_URL || '',
	},
	getters: {
		isAdmin: (state) => {
			return state.user?.admin === '1' ? true : false;
		},
		isDev: (state) => {
			return state.user?.dev === '1' ? true : false;
		},
		isAccountManager: (state) => {
			return state.user?.account_manager === '1' ? true : false;
		},
		isRemoval: (state) => {
			return state.user?.removal === '1' ? true : false;
		},
		isSupport: (state) => {
			return state.user?.support === '1' ? true : false;
		},
		// isSales: (state) => {
		//     return state.user.sales === "1" ? true : false;
		// },
	},
	mutations: {
		SET_PROGRESS(state, total) {
			state.progress = total;
		},
		SET_LOADING(state, bool) {
			state.isLoading = bool;
		},
		SET_IS_LOGGED_IN(state, bool) {
			state.isLoggedIn = bool;
		},
		SET_USER(state, user) {
			state.user = user;
		},
	},
	actions: {},
});

export const useStore = () => store

export default store;
