import Vue from 'vue';
import App from './App.vue';
import { createPinia, PiniaVuePlugin } from 'pinia'

import router from './router';
import store from './store';

import './filters/index.js';

import DashboardPlugin from './plugins/dashboard-plugin';
import FeatureFlagsPlugin from './plugins/feature-flags-plugin';

Vue.config.productionTip = false;
Vue.use(DashboardPlugin, store);
Vue.use(FeatureFlagsPlugin);

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

new Vue({
	router,
	store,
	render: h => h(App),
	pinia
}).$mount('#app');
